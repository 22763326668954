import React from 'react';
import styled from 'styled-components';
import { WebsiteDefaultPage } from '../components/Page';
import { Color, mediaQueries } from '../styles';
import { graphql } from 'gatsby';

import Footer from '../components/Global/Footer';
import { CardGrid } from '../components/Gui/Layout';
import { MediaCard } from '../components/Content/Card';
import { PodcastIcon } from '../components/Svg';

const Wrapper = styled.div`
  margin: 134px auto 244px;
  max-width: 40rem;

  > h1 {
    font-size: 2rem;
    line-height: calc(25 / 20);
    letter-spacing: calc(-0.68em / 20);
    margin-bottom: 40px;
  }

  ${mediaQueries('md')`
    max-width: 58.6rem;
  `};

  ${mediaQueries('xl')`
    max-width: 90rem;
  `};
`;

const Podcasts = ({ data: { datoCmsMikesWebsite } }) => {
  const { podcasts } = datoCmsMikesWebsite;

  return (
    <WebsiteDefaultPage bodyBackground={Color.GREY_ONE}>
      <Wrapper>
        <h1>Podcasts</h1>
        <CardGrid>
          {podcasts.map(podcast => {
            return <MediaCard {...podcast} icon={PodcastIcon} />;
          })}
        </CardGrid>
      </Wrapper>
      <Footer />
    </WebsiteDefaultPage>
  );
};

export default Podcasts;

export const query = graphql`
  query PodcastsPageQuery {
    datoCmsMikesWebsite {
      podcasts {
        ...MediaLink
      }
    }
  }
  fragment MediaLink on DatoCmsMediaLink {
    title
    description
    url
  }
`;
